import React from 'react';
import './src/utils/font.loader';

import { MuiWrapper } from './src/mui/wrapper';
import { BreezerrWrapper } from './breezerr';
import { ReferralParamsWrapper } from './src/utils/referral.helper';
import { BreakpointWrapper } from './src/utils/is.breakpoint';
import { CoBrandingContextWrapper } from './src/utils/partner.context';
import { GTM, GTMScript } from './src/utils/gtm';
import { GoogleWrap } from './src/utils/google.maps.context';
import { WrapRootElementBrowserArgs, WrapPageElementBrowserArgs } from 'gatsby';


export const wrapRootElement = ({ element, pathname }: WrapRootElementBrowserArgs) => {
    return <>
        <GoogleWrap>
            <ReferralParamsWrapper>
                <BreakpointWrapper>
                    {element}
                </BreakpointWrapper>
            </ReferralParamsWrapper>
        </GoogleWrap>
    </>;
};

export const wrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
    return <>
        <MuiWrapper>
            <BreezerrWrapper>
                <CoBrandingContextWrapper>
                    {element}
                </CoBrandingContextWrapper>
            </BreezerrWrapper>
        </MuiWrapper>

        <GTMScript />
        <GTM />
    </>
};


// export const shouldUpdateScroll = () => {
//     window.scrollTo({
//         top: 0,
//         behavior: 'instant'
//     });
    
//     return false;
// };
