import WebFont from 'webfontloader';

const WebFontConfig: WebFont.Config = {
    google: {
      families: ['Open Sans:400,600,700'],
    },
    active: () => {
        setTimeout(() => {
            //console.log('GTM: PageReady');
            window?.dataLayer?.push({ 'event': 'PageReady' });
        }, 300);
    }
}


WebFont.load(WebFontConfig);