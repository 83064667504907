import { ConfigType } from "./types";

const config = {
    prefix: 'lrx',
    unit: 'rem',
    spacer: 1,
    gutter: 3,
    fontFamily: `"Open Sans", sans-serif`,
    headingFontFamily: `"Open Sans", sans-serif`,
    breakpoints: {
        '': { media: 0, container: '85vw', base: 10 },
        xxs: { media: 320, container: '85vw', base: 10 },
        xs: { media: 375, container: '85vw', base: 10 },
        sm: { media: 425, container: '85vw', base: 10 },
        smd: { media: 636, container: '85vw', base: 10 },
        md: { media: 768, container: '90vw', base: 9 },
        '^sm': { media: 1024, height: 700, container: '90vw', base: 9 },
        '^md': { media: 1024, height: 780, container: '90vw', base: 9 },
        lg: { media: 1024, container: '100rem', base: 9 },
        xl: { media: 1280, container: 1210, base: 9 },
        xxl: { media: 1440, container: 1210, base: 10 },
    },
    colors: {
        text: {
            main: '#243547'
        },
        heading: {
            main: '#243547'
        },
        primary: {
            main: 800,
            800: ['#243547', '#fff'],
            700: ['#34475B', '#fff'],
            600: ['#4B5B6D', '#fff'],
            500: ['#66778A', '#fff'],
        },
        secondary: {
            main: 200,
            100: ['#EEF3F8', '#243547'],
            200: ['#C4DFEB', '#243547'],
            300: ['#B7D7E5', '#243547'],
            400: ['#8ABCD1', '#243547'],
            500: ['#63A8C5', '#243547'],
            600: ['#67A4BE', '#243547'],
        },
        accent: {
            main: 600,
            100: ['#FBBD92', '#fff'],
            200: ['#F8B587', '#fff'],
            500: ['#FF9852', '#fff'],
            600: ['#FF9248', '#fff'],
            700: ['#FF832F', '#fff'],
        },
        grey: {
            main: 100,
            50: ['#FAFAFA', '#243547'],
            100: ['#F7F8FA', '#243547'],
            200: ['#E6EBEE', '#243547'],
            400: ['#CFD8E2', '#243547'],
            500: ['#BEC7D1', '#243547'],
        },
        white: {
            main: 500,
            500: ['#fff', '#243547']
        },
        error: {
            main: 500,
            500: ['#DE623B', '#fff']
        },
        success: {
            main: 100,
            100: ['#C8DEC7', '#9AB499'],
            300: ['#9AB499', '#709F90'],
        },
        transparent: {
            main: 500,
            500: ['rgba(0,0,0,0)', 'rgba(0,0,0,0)']
        },
        shadow: {
            main: 900,
            500: ['rgba(20, 20, 20, 0.04)', '#fff'],
            600: ['rgba(20, 20, 20, 0.06)', '#fff'],
            700: ['rgba(20, 20, 20, 0.05)', '#fff'],
            800: ['rgba(20, 20, 20, 0.3)', '#fff'],
            900: ['rgba(20, 20, 20, 0.4)', '#fff'],
        }
    }
}

export default config;