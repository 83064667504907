import { Config } from "@breezerr";
import type { Components } from "@mui/material";
import { TextLink } from "@lr-components/text.link";

export const MuiButtonTheme: Components = {
    MuiButton: {
        defaultProps: {
            variant: 'contained',
            color: 'secondary',
            disableElevation: true,
            LinkComponent: TextLink
        },
        styleOverrides: {
            root: {
                display: 'inline-flex',
                boxSizing: 'border-box',
                textTransform: 'none',
                whiteSpace: 'nowrap',
            },

            sizeSmall: {
                height: '3rem',
                boxSizing: 'border-box',
                borderRadius: '1rem',
                padding: '0rem 1.5rem 0 1.5rem',
                fontSize: '1.3rem',
                fontWeight: '500',
            },
            sizeMedium: {
                height: '4.5rem',
                boxSizing: 'border-box',
                borderRadius: '1rem',
                padding: '0rem 3rem 0 3rem',
                fontSize: '1.1rem',
                fontWeight: '600',
            },
            sizeLarge: {
                height: '6rem',
                boxSizing: 'border-box',
                borderRadius: '1rem',
                padding: '0.1rem 5rem 0 5rem',
                fontSize: '1.5rem',
                fontWeight: '500',
                letterSpacing: '0rem',
                minWidth: 'unset'
            },

            // Contained

            // colors
            containedSecondary: {
                color: Config.colors.accent['600'][1],
                backgroundColor: Config.colors.accent['600'][0],
                '&:hover': {
                    color: Config.colors.accent['700'][1],
                    backgroundColor: Config.colors.accent['700'][0],
                }
            },
            // Outlined
            outlinedSizeSmall: {
                border: `2px solid ${Config.colors.primary[800][0]}`,
            },
            outlinedSizeMedium: {
                border: `2px solid ${Config.colors.primary[800][0]}`,
            },
            outlinedSizeLarge: {
                border: `2px solid ${Config.colors.primary[800][0]}`,
            },
            // colors
            outlinedPrimary: {
                color: Config.colors.primary['800'][0],
                backgroundColor: 'transparent',
                '&:hover': {
                    color: Config.colors.primary['800'][1],
                    backgroundColor: Config.colors.primary[800][0],
                    borderColor: 'transparent',
                    borderWidth: '2px'
                }
            },
            // Text
            textSecondary: {
                color: Config.colors.accent['700'][0],
            },
        }
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                padding: 0,
            },
            sizeLarge: {
                width: '6rem',
                height: '6rem',
            },
            sizeMedium: {
                width: '4.6rem',
                height: '4.6rem',
            },
            sizeSmall: {
                width: '3rem',
                height: '3rem',
            }
        }
    }
};