import { env } from '@lr-env';
import { Script } from 'gatsby';
import React, { useContext } from 'react';

type ContextType = typeof google | undefined;

export const GoogleContext = React.createContext<ContextType>({} as typeof google);


export const GoogleWrap = (props: { children: React.ReactNode | React.ReactNode[] }) => {
    const [_google, setGoogle] = React.useState<ContextType>();

    React.useEffect(() => {
        if ( typeof window !== 'undefined' ) {
            window.addEventListener('message', handleGPLLoaded, { passive: true });
        }

        return () => {
            if ( typeof window !== 'undefined' ) {
                window.removeEventListener('message', handleGPLLoaded);
            }
        };
    }, []);

    const handleGPLLoaded = async (event: MessageEvent) => {
        const { data, origin } = event;
        
        if ( origin === window.location.origin && data === 'gpl:loaded' ) {
            setGoogle(google);
        }
    };

    return (
        <GoogleContext.Provider value={_google}>
            <Script
                async
                id='google-places-library'
                onLoad={() => {
                    if ( typeof window !== 'undefined' ) {                        
                        window.postMessage('gpl:loaded');
                    }
                }}
                src={`https://maps.googleapis.com/maps/api/js?key=${env.GATSBY_GMP_API_KEY}&loading=async&libraries=places`}
            />
            
            {props.children}
        </GoogleContext.Provider>
    );
};


export const useGoogle = () => useContext(GoogleContext);