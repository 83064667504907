export const env = {
    /**
     * Hostname of deployed utils, api, etc...
     * @description Leave blank to use paths relative to the production domain
     */
    DEPLOYED_HOST: '', // Leave blank to use paths relative to the production domain
    /**
     * API Host
     */
    API_HOST: 'https://app.leveragerx.com/api',
    /**
     * Azure Functions Host
     */
    FUNCTIONS_HOST: 'https://cms-funcs.leveragerx.com',
    /**
     * Customer.io workspace Name
     */
    CIO_WORKSPACE: 'lrx_website_leads',
    /**
     * Google Tag Manager ID
     */
    GTM_ID: 'GTM-W3HLV6Z',
    /**
     * Google Maps Platform API Key
     */
    GATSBY_GMP_API_KEY: process.env.GATSBY_GMP_API_KEY,

    BRANCH: 'main'
}

/**
 * 
 */
export const IS_DEVELOP = false;
